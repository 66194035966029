<template>
	<div
		class="full-height flex-column"
	>
		<Search
			:search="item_search"
			:option="search_option"

			@change="getSearch(1)"
			@click="getSearch(1)"
		>
			<template
				slot="add"
			>
				<Date_picker
					:date="item_search.sDate"
					@click="setDate"
					class="mr-10"
				></Date_picker>

				<button
					slot="add"
					class="pa-5-10 mr-10 input-box-inline size-px-12"
					@click="onSearchAgency"
				>{{ text_search_agency }}</button>

				<select
					v-model="item_search.virtual_uid"
					class="mr-10 input-box-inline size-px-12"
					@change="getSearch(1)"
				>
					<option value="">지갑 구분</option>
					<option
						v-for="(virtual, v_index) in items_virtual"
						:key="'virtual_' + v_index"
						:value="virtual.uid"
					>{{ virtual.virtual_name }}</option>
				</select>
			</template>

		</Search>

		<div
			class="mt-10 bg-white pa-10 full-height overflow-y-auto"
		>
			<div v-if="items.length > 0">
				<table class="table table-even">
					<colgroup>
						<col width="80px" />
						<col width="auto" />
						<col width="auto" />

						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
					</colgroup>
					<thead>
					<tr>
						<th>
							<input
								v-model="is_all"
								type="checkbox"
							/>
						</th>
						<th>소속</th>
						<th>아이디</th>

						<th>상점명</th>

						<th>지갑 구분</th>

						<th>조회내역</th>
						<th>정산등록</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="(item, index) in item_list"
						:key="'item_' + index"
						:class="{ 'bg-select': item.uid == item_new.uid }"
					>
						<td>
							<input
								v-model="item.is_all"
								type="checkbox"
							/>
						</td>
						<td>{{ item.agency_upper_name }}</td>
						<td>{{ item.account_id }}</td>
						<td>{{ item.shop_sort }}</td>
						<td>{{ item.shop_name }}</td>
						<td>{{ item.virtual_name }}</td>

						<td
							v-if="item.settle"
						>
							정산금액: {{ item.settle.amount | makeComma }}
							<br/>
							입금금액: {{ item.settle.deposit | makeComma}}
							<br/>
							송금금액: {{ item.settle.transfer | makeComma}}
						</td>
						<td
							v-else
						>
							-
						</td>
						<td>
							<template
								v-if="item.settle || item.income_amount"
							>
								<div class="justify-space-between">
									조회 금액: <span>{{ item.settle ? item.settle.amount : item.income_amount | makeComma}}</span>
								</div>
								<div class="justify-space-between items-center mt-5">
									차감 금액: <input v-model="item.minus_amount" type="number" :rules="rules.max(item, 'minus_amount', 9)" class="input-box-inline width-50 text-right" />
								</div>
								<div class="justify-space-between mt-5">
									정산 금액: <span>{{ item.amount | makeComma}}</span>
								</div>
							</template>
							<template
								v-else
							>
								-
							</template>
						</td>
					</tr>
					</tbody>
				</table>


				<div
					class="mt-10 text-right"
				>
					<input v-model="item_search.sDate" class="input-box-inline mr-10 " readonly/>

					<select
						v-model="item_search.virtual_uid"
						class="mr-10 input-box-inline size-px-12"
						disabled
					>
						<option value="">지갑 구분</option>
						<option
							v-for="(virtual, v_index) in items_virtual"
							:key="'virtual_' + v_index"
							:value="virtual.uid"
						>{{ virtual.virtual_name }}</option>
					</select>

					<button
						class="btn-inline btn-primary mr-10"
						:disabled="!list_all.length"

						@click="onSettlementSearch"
					>선택 정산 조회</button>

					<button
						class="btn-inline btn-primary mr-10"
						:disabled="!list_all.length"

						@click="onSettlement"
					>선택 정산 등록</button>

					<button
						class="btn-inline btn-primary"

						@click="onSettlementAll"
					>전체 정산 등록</button>
				</div>

				<Pagination
					:program="program"
					:align="'center'"
					:options="item_search"

					class="mt-auto"
					@click="getSearch"
				></Pagination>
			</div>

			<Empty
				v-else
			></Empty>
		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"
		></Excel>

		<AgencySearch
			v-if="is_search_agency"
			agency_type="A001003"

			@cancel="offSearchAgency"
			@click="setSearchAgency"
		></AgencySearch>
	</div>
</template>

<script>

import Pagination from "../../components/Pagination";
import Search from "../Layout/Search";
import Excel from "../../components/Excel";
import Empty from "@/view/Layout/Empty";
import AgencySearch from "../Agency/AgencySearch";
import Date_picker from "../../components/DatePicker";

export default {
	name: 'SettlementSearch'
	, components: {Date_picker, Empty, Excel, Search, Pagination, AgencySearch}
	, props: ['Axios', 'rules', 'TOKEN', 'date', 'codes', 'user']
	, data: function (){
		return {
			program: {
				name: '입금 정산 조회'
				,top: true
				,title: true
			}
			,options: {

			}
			, item_search: this.$storage.init({
				page: 1
				, search_type: 'seller_info.shop_name'
				, search_value: ''
				, list_cnt: 10
				, account_status: ''
				, agency_type: ''
				, agency_upper: ''
				, agency_upper_name: ''
				, sales_type: 0
				, sDate: this.$date.getToday('-')
				, virtual_uid: ''
			})
			,search_option:{

				is_item: false
				,is_excel: false
				,is_cnt: true
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '상점명', column: 'seller_info.shop_name'}
				]
				,select: [
					{ name: '사용 여부', column: 'account_status', items: [
							{ name: '사용', column: '1'}
							,{ name: '미사용', column: '0'}
						]
					}
				]
				, date_change: true
			}
			,items: [

			]
			,item_new: {

			}
			,is_item: false
			,is_excel: false
			,excel_data: {
				name: '영업점 목록'
				,header: [
					{ key: 0, name: '구분', column: 'type_name'}
					,{ key: 0, name: '영업점명', column: 'shop_name'}
					,{ key: 0, name: '아이디', column: 'admin_id'}
					,{ key: 0, name: '이름', column: 'admin_name'}
					,{ key: 0, name: '연락처', column: 'admin_tell'}
					,{ key: 0, name: '가입일', column: 'wDate'}
				]
				,content: null
			}
			, items_upper: []
			, is_search_agency: false
			, is_multi: false
			, is_all: false
			, is_mode: false
			, items_virtual: []
			, now: 0
		}
	}
	,computed: {
		item_list: function (){

			return this.items.filter((item) => {

				return item
			})
		}
		,btn_name: function (){
			if(this.item_new.uid){
				return '저장'
			}else{
				return '신규 등록'
			}
		}
		, text_search_agency: function(){
			let t = '소속 구분'

			if(this.item_search.agency_upper){
				t = this.item_search.agency_upper_name
			}
			return t
		}
		, list_all: function(){
			let t = []
			this.items.filter((item) => {
				if(item.is_all){
					t.push({
						shop_name: item.shop_name
						, shop_sort: item.shop_sort
					})
				}
			})
			return t
		}
		, list_settlement: function(){

			let t = []
			this.items.filter((item) => {
				if(item.is_all && (item.settle?.amount > 0 || item.minus_amount > 0 || item.income_amount > 0)){
					t.push({
						agency_id: item.agency_id
						, agency_upper_id: item.agency_upper_id
						, amount: item.settle?.amount ? item.settle?.amount : item.income_amount
						, minus: item.minus_amount
						, shop_uid: item.shop_uid
						, virtual_uid: item.virtual_uid
					})
				}
			})
			return t
		}
		, list_settlement_all: function(){

			let t = []
			this.items.filter((item) => {
				if(item.settle?.amount > 0 || item.minus_amount > 0 || item.income_amount > 0){
					t.push({
						agency_id: item.agency_id
						, agency_upper_id: item.agency_upper_id
						, amount: item.settle?.amount ? item.settle?.amount : item.income_amount
						, minus: item.minus_amount
						, shop_uid: item.shop_uid
						, virtual_uid: item.virtual_uid
					})
				}
			})
			return t
		}

		, depost_virtual_name: function(){
			let t = ''
			this.items_virtual.filter( (item) => {
				if(this.item_search.virtual_uid == item.uid ){
					t = item.virtual_name
				}
			})
			return t
		}
	}
	,methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'settlement/getOutSettlementList'
					,data: this.item_search
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.item_search, 'total_count', result.data.tCnt)
					this.search_option.tCnt = result.data.tCnt
					this.search_option.cnt = result.data.cnt
					this.$storage.setQuery(this.item_search)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getSearch: function(page = 1){
			this.is_mode = false
			if(!page){
				page = 1
			}
			this.item_search.page = page
			this.getData()
		}
		, getDistributorList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgencyUpper'
					,data: {
						agency_type: 'A001003'
					}
				})
				if(result.success){
					this.items_upper = result.data
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onSearchAgency: function(){
			this.is_search_agency  = true
		}
		, offSearchAgency: function(){
			this.is_search_agency  = false
		}
		, setSearchAgency: function(upper) {
			console.log(upper)
			this.item_search.agency_upper = upper.uid
			this.item_search.agency_upper_name = upper.agency_name
			this.offSearchAgency()
			this.getSearch()
		}
		, getSettlementSearch: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/getSettlementSearch'
					,data: {
						search_list: JSON.stringify([this.list_all[this.now]])
						, sDate: this.item_search.sDate
						, eDate: this.item_search.sDate
					}
				})
				if(result.success){
					result.data.filter( (settlement) => {
						this.items.filter( (item) => {
							if(item.shop_sort == settlement.user_id || item.shop_name == settlement.shop_name){
								this.$set(item, 'settle', settlement?.data?.settle[0]?.settle)
							}
						})
					})
					this.now++
					if(this.now == this.list_all.length){
						this.is_mode = true
						this.now = 0
					}else{
						await this.getSettlementSearch()
					}
				}else{
					throw result.message
				}
			}catch(e){
				console.log(e)
				this.is_mode = false
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postSettlement: async function(type){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'settlement/postSettlement'
					,data: {
						search_list: JSON.stringify(type == 'all' ? this.list_settlement_all : this.list_settlement)
						, sDate: this.item_search.sDate
						, virtual_uid: this.item_search.virtual_uid
						, type: type
					}
				})
				if(result.success){
					this.is_mode = false
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onSettlement: function(){
			if(this.item_search.virtual_uid){
				if(confirm('선택하신 상점의 ' + this.item_search.sDate + '일 [' + this.depost_virtual_name +'] 정산 내역을 등록하시겠습니까?')){
					this.postSettlement()
				}
			}else{
				alert('가상계좌를 선택하세요')
			}
		}
		, onSettlementAll: function(){
			if(this.item_search.virtual_uid){
				if(confirm("전체 정산 등록시 지급된 정산내역을 제외하고 삭제 후 재등록합니다. \r\n" + this.item_search.sDate + '일 [' + this.depost_virtual_name +'] 전체 정산 내역을 등록하시겠습니까?')){
					this.postSettlement('all')
				}
			}else{
				alert('가상계좌를 선택하세요')
			}
		}
		, onSettlementSearch: function(){
			if(this.item_search.virtual_uid){
				if(confirm('선택하신 상점의 ' + this.item_search.sDate + '일 [' + this.depost_virtual_name +'] 정산 내역을 조회하시겠습니까?')){
					this.getSettlementSearch()
				}
			}else{
				alert('가상계좌를 선택하세요')
			}
		}
		, getVirtualList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					, url: 'management/getVirtualList'
					, data: {
					}
				})
				if (result.success) {
					this.items_virtual = result.data
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, setDate: function(date){
			console.log('setDate', date)
			this.item_search.sDate = date

			this.getData()
		}
	}
	, async created() {

		this.$emit('onLoad', this.program)

		await this.getData()
		await this.getDistributorList()
		await this.getVirtualList()
	}
	, watch: {
		is_all: {
			handler: function(call){

				this.items.filter( (item) => {
					this.$set(item, 'is_all', call)
				})
			}
		}
	}
}
</script>